import React, { useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import {
  AuthRoute,
  DashboardRoute,
  SuperadminAuthRoute,
  SuperadminDashboardRoute,
} from "./RouterHelpers";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import _ from "lodash";
import {
  getPathNames,
  SUPERADMIN,
  ADMIN,
  SPECIALIST,
  CLIENT,
} from "../helpers/router_helper";
import { motion } from "framer-motion";
import { pageTransition, pageVariants } from "../helpers/animation_helper";
import { useSelector } from "react-redux";

const SUPERADMIN_AUTHENTICATION = React.lazy(() =>
  import("../pages/SuperadminLogin")
);
const USER_AUTHENTICATION = React.lazy(() => import("../pages/SignIn"));
const ERROR_PAGE = React.lazy(() => import("../pages/404/NotFound"));

const RootRouter = ({ isAuthenticated, isSuperadminAuthenticated }) => {
  const location = useLocation();

  useEffect(() => {
    let paths = getPathNames();
    let path = paths[paths.length - 1] || "";
    document.title = `${_.capitalize(path.replaceAll("_", " "))}`;
  }, [location]);

  const AnimationExtendedComponent = (props) => {
    let Module = props.jsx;
    return (
      <>
        <motion.div
          key={props.componentKey}
          initial="initial"
          animate="in"
          variants={pageVariants}
          transition={pageTransition}
        >
          <Module />
        </motion.div>
      </>
    );
  };

  const userRole = isAuthenticated || isSuperadminAuthenticated;
  const modules =
    userRole === SUPERADMIN
      ? SUPERADMIN_MODULES[SUPERADMIN]
      : ALL_USER_MODULES[userRole] || {};

  return (
    <>
      <ToastContainerComponent newestOnTop />
      <Routes>
        {/* Auth Routes */}
        {![SUPERADMIN].includes(userRole) ? (
          <Route
            path="/login"
            element={<AuthRoute component={USER_AUTHENTICATION} />}
          />
        ) : null}
        {![SUPERADMIN].includes(userRole) ? (
          <Route
            path="/user/login"
            element={<AuthRoute component={USER_AUTHENTICATION} />}
          />
        ) : null}
        {![ADMIN, CLIENT, SPECIALIST].includes(userRole) ? (
          <Route
            path="/superadmin/login"
            element={
              <SuperadminAuthRoute component={SUPERADMIN_AUTHENTICATION} />
            }
          />
        ) : null}

        {/* Authenticated Routes */}
        {userRole &&
          Object.keys(modules).map((mod, idx) => (
            <Route
              key={`page_${idx}`}
              path={
                (userRole === SUPERADMIN ? "/superadmin/" : "/") +
                mod.toLowerCase()
              }
              element={
                userRole === SUPERADMIN ? (
                  <SuperadminDashboardRoute
                    component={
                      <AnimationExtendedComponent
                        componentKey={`${mod}/${idx}`}
                        jsx={modules[mod]}
                      />
                    }
                  />
                ) : (
                  <DashboardRoute
                    component={
                      <AnimationExtendedComponent
                        componentKey={`${mod}/${idx}`}
                        jsx={modules[mod]}
                      />
                    }
                  />
                )
              }
            />
          ))}

        {/* Redirect to Dashboard after Login */}
        {userRole && (
          <Route
            path="/"
            element={
              <Navigate
                to={userRole === SUPERADMIN ? "/superadmin/" : "/dashboard"}
                replace
              />
            }
          />
        )}

        {/* Default Redirect to Login */}
        {!userRole && (
          <Route path="/" element={<Navigate to="/login" replace />} />
        )}

        {/* 404 Not Found */}
        <Route path="*" element={<ERROR_PAGE />} />
      </Routes>
    </>
  );
};

const ALL_USER_MODULES = {
  [ADMIN]: {
    DASHBOARD: React.lazy(() => import("../pages/Dashboard")),
    TASKS: React.lazy(() => import("../pages/admin/UserBoard")),
    WORKBOARD: React.lazy(() => import("../pages/admin/WorkBoard")),
    SPECIALIST: React.lazy(() => import("../pages/admin/Specialist")),
    TIMESHEET: React.lazy(() => import("../pages/admin/Timesheet")),
    INSIGHTS: React.lazy(() => import("../components/shared/Insights")),
    EARNINGS: React.lazy(() => import("../pages/admin/Earnings")),
    RESET_PASSWORD: React.lazy(() => import("../pages/ResetPassword")),
  },
  [SPECIALIST]: {
    DASHBOARD: React.lazy(() => import("../pages/Dashboard")),
    TASKS: React.lazy(() => import("../pages/specialist/Task")),
    DELIVERIES: React.lazy(() => import("../pages/specialist/Deliveries")),
    TIMESHEET: React.lazy(() => import("../pages/specialist/Timesheet")),
    INSIGHTS: React.lazy(() => import("../components/shared/Insights")),
    EARNINGS: React.lazy(() => import("../pages/specialist/Earnings")),
    RESET_PASSWORD: React.lazy(() => import("../pages/ResetPassword")),
  },
  [CLIENT]: {
    DASHBOARD: React.lazy(() => import("../pages/Dashboard")),
    TASKS: React.lazy(() => import("../pages/client/Task")),
    DELIVERIES: React.lazy(() => import("../pages/client/Deliveries")),
    TEAM: React.lazy(() => import("../pages/client/Team")),
    PROJECTS: React.lazy(() => import("../pages/client/Projects")),
    BRANDS: React.lazy(() => import("../pages/client/Brands")),
    INSIGHTS: React.lazy(() => import("../components/shared/Insights")),
    SUBSCRIPTIONS: React.lazy(() => import("../pages/client/Subscriptions")),
    RESET_PASSWORD: React.lazy(() => import("../pages/ResetPassword")),
  },
};

const SUPERADMIN_MODULES = {
  [SUPERADMIN]: {
    DASHBOARD: React.lazy(() => import("../pages/Dashboard")),
    USERS: React.lazy(() => import("../pages/superadmin/ManageUsers")),
    CLIENTS: React.lazy(() => import("../pages/superadmin/ManageClients")),
    CATEGORIES: React.lazy(() => import("../pages/superadmin/Categories")),
    PACKAGES: React.lazy(() => import("../pages/superadmin/Packages")),
    SUBSCRIPTIONS: React.lazy(() => import("../pages/superadmin/Subscription")),
    RESET_PASSWORD: React.lazy(() => import("../pages/ResetPassword")),
  },
};

export default RootRouter;

const ToastContainerComponent = (props) => {
  const currentTheme = localStorage.getItem("theme") || "light";

  return (
    <ToastContainer
      theme={currentTheme}
      {...props}
      position={"bottom-center"}
    />
  );
};
